import { useStaticQuery, graphql } from "gatsby"

export const useQueryAccelerator = () => {
  const { allPrismicAccelerator } = useStaticQuery(
    graphql`
      query QueryAccelerator {
        allPrismicAccelerator {
            nodes {
              data {
                body {
                  ... on PrismicAcceleratorBodyBanner {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                      button_label {
                        html
                        text
                        raw
                      }
                      button_link {
                        url
                        type
                        link_type
                      }
                    }
                  }
                }
                body2 {
                  ... on PrismicAcceleratorBody2Quote {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      quote {
                        html
                        text
                        raw
                      }
                      author {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
                body3 {
                  ... on PrismicAcceleratorBody3Achievement {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
                body4 {
                  ... on PrismicAcceleratorBody4AboutDshAccelerator {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      sub_title {
                        html
                        text
                        raw
                      }
                      button_label {
                        html
                        text
                        raw
                      }
                      button_link {
                        url
                        type
                        link_type
                      }
                    }
                    items {
                      icon {
                        url
                        alt
                      }
                      description {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
                body5 {
                  ... on PrismicAcceleratorBody5AboutUs {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                      image {
                        url
                        alt
                      }
                    }
                  }
                }
                body6 {
                  ... on PrismicAcceleratorBody6ProgramDates {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                    }
                    items {
                      thumbnail {
                        alt
                        url
                      }
                      location {
                        html
                        text
                        raw
                      }
                      description {
                        html
                        text
                        raw
                      }
                      start_date(formatString: "MMMM DD")
                      end_date(formatString: "MMMM DD, YYYY")
                      link_label {
                        html
                        text
                        raw
                      }
                      link {
                        url
                        type
                        link_type
                      }
                    }
                  }
                }
                body7 {
                  ... on PrismicAcceleratorBody7BlockButton {
                    primary {
                      title {
                        html
                        text
                        raw
                      }
                      button_label {
                        html
                        text
                        raw
                      }
                      button_link {
                        url
                        type
                        link_type
                      }
                    }
                  }
                }
                body1 {
                    ... on PrismicAcceleratorBody1GeneralCard {
                      primary {
                        title {
                          html
                          text
                          raw
                        }
                        robot {
                          html
                          text
                          raw
                        }
                        image {
                          url
                          alt
                        }
                        googlebot {
                          html
                          text
                          raw
                        }
                        favicon {
                          alt
                          url
                        }
                        description {
                          html
                          text
                          raw
                        }
                        bingbot {
                          html
                          text
                          raw
                        }
                      }
                      slice_type
                    }
                    ... on PrismicAcceleratorBody1OpenGraph {
                      primary {
                        og_url {
                          url
                          type
                          link_type
                        }
                        og_type {
                          html
                          text
                          raw
                        }
                        og_title {
                          html
                          text
                          raw
                        }
                        og_site_name {
                          html
                          text
                          raw
                        }
                        og_locale {
                          html
                          text
                          raw
                        }
                        og_image_width {
                          html
                          text
                          raw
                        }
                        og_image_height {
                          html
                          text
                          raw
                        }
                        og_image {
                          alt
                          url
                        }
                        og_description {
                          html
                          text
                          raw
                        }
                      }
                      slice_type
                    }
                    ... on PrismicAcceleratorBody1TwitterCard {
                      primary {
                        title {
                          html
                          text
                          raw
                        }
                        image {
                          alt
                          url
                        }
                        description {
                          html
                          text
                          raw
                        }
                      }
                      slice_type
                    }
                    ... on PrismicAcceleratorBody1PinterestProductPin {
                      slice_type
                      primary {
                        title {
                          html
                          text
                          raw
                        }
                        description {
                          html
                          text
                          raw
                        }
                        price
                        currency
                        availability
                      }
                    }
                  }
              }
            }
          }
      }
      `
  )

  return {
    allPrismicAccelerator
  }
}