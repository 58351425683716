import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import { isMobile } from "../../../hooks/checkMobile";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../../components/RichtextHelper/CustomText";
import { redirectPage } from "../../../hooks/redirect";

export const About = ({ items, primary }) => {
  // const [seeMore, setSeemore] = useState(false);
  const [listItem, setListItem] = useState(items);

  useEffect(() => {
    let listTamp = [...items];
    if (isMobile) {
      setListItem(listTamp.splice(0, 6));
    }
    //eslint-disable-next-line
  }, []);

  // const handleListMore = () => {
  //   let listTamp = [...items];
  //   listItem.length !== items.length
  //     ? setListItem(listTamp)
  //     : setListItem(listTamp.splice(0, 6));
  // };
  if (!items || !primary) {
    return null;
  }
  return (
    <WrapperSection>
      <WrapperTitle>
        <Wrapper>
          <Sub
            font={typography.type.code}
            color={color.primary.blue}
            // weight={typography.weight.primary}
            tag="h5"
            weight={typography.weight.regular1}
            capital="uppercase"
          >
            {primary?.sub_title?.text}
            {/* <RichText
              render={primary?.description?.raw}
              htmlSerializer={htmlSerializer}
            /> */}
          </Sub>
        </Wrapper>
        <Wrapper>
          <Title
            tag="h3"
            weight={typography.weight.regular1}
            color={color.primary.blue}
            capital="uppercase"
          >
            {/* {data.title} */}
            <RichText
              render={primary?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
        </Wrapper>
        {!isMobile && (
          <Btn
            type="primary"
            onClick={() =>
              redirectPage({
                url: primary?.button_link?.url,
                type: "accelerator-form",
                link_type: "Document"
              })
            }
          >
            {primary?.button_label?.text}
          </Btn>
        )}
      </WrapperTitle>
      <WrapperContent>
        <WrapperGeneral>
          <List>
            {listItem &&
              listItem?.map((item, i) => {
                return (
                  <ListItem key={i}>
                    <HeaderItem>
                      <Icon src={item?.icon?.url} />
                    </HeaderItem>
                    <BoxContent>
                      <DescriptionItem
                        size={typography.size.m1}
                        weight={typography.weight.regular}
                        lineHeight={30}
                        font={typography.type.primary}
                        color={color.netraul.black90}
                      >
                        {item?.description?.text}
                      </DescriptionItem>
                    </BoxContent>
                  </ListItem>
                );
              })}
          </List>
          <Footer>
            {/* {items.length > 4 && (
              <GroupButton>
                <ButtonExtended onClick={handleListMore} type="primary">
                  {listItem.length !== items.length ? "See more" : "See less"}
                </ButtonExtended>
              </GroupButton>
            )} */}
            {isMobile && (
              <Btn
                type="primary"
                onClick={() =>
                  redirectPage({
                    url: primary?.button_link?.url,
                    type: primary?.button_link?.type,
                    link_type: primary?.button_link?.link_type,
                  })
                }
              >
                {primary?.button_label?.text}
              </Btn>
            )}
          </Footer>
        </WrapperGeneral>
      </WrapperContent>
    </WrapperSection>
  );
};

const Wrapper = styled.div``;
const WrapperSection = styled.div`
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 0 130px;
  margin-top: 140px;
  margin-bottom: 140px;
  @media (max-width: 1300px) {
    padding: 0 60px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
    margin-top: 80px;
    margin-bottom: 100px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const WrapperTitle = styled.div`
  width: 30%;
  @media (max-width: 1400px) {
    width: 40%;
  }
  @media (max-width: 1100px) {
    width: 100%;
    margin-bottom: 50px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 50px;
    text-align: center;
  }
`;
const Btn = styled(Button)`
  margin-top: 32px;
  @media (max-width: 650px) {
    margin-top: 60px;
  }
`;
const Footer = styled.div`
  text-align: center;
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 1400px) {
  }
  @media (max-width: 650px) {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
`;
const Sub = styled(Text)`
  letter-spacing: -0.015em;
  margin-bottom: 7px;
`;
const WrapperContent = styled.div`
  flex: 1;
  margin: 0 auto;
  @media (max-width: 1400px) {
  }
  @media (max-width: 650px) {
  }
`;
const WrapperGeneral = styled.div``;
const DescriptionItem = styled(Text)``;
const BoxContent = styled.div`
  span {
    white-space: inherit;
  }
  @media (max-width: 650px) {
    margin-top: 28px;
  }
`;
const List = styled.div`
  row-gap: 32px;
  column-gap: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 1000px) {
    row-gap: 20px;
  }
`;
const ListItem = styled.div`
  width: 240px;
  max-height: 100%;
  background-color: ${color.primary.blue05};
  padding: 24px;
  box-sizing: border-box;
  @media (max-width: 1100px) {
    width: calc((100% / 3) - 16px);
  }
  @media (max-width: 900px) {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`;
const Icon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 40px;
  max-height: 40px;
`;
const HeaderItem = styled.div`
  padding-bottom: 28px;
  @media (max-width: 900px) {
    padding-bottom: 0;
    padding-right: 20px;
  }
`;
