import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { Text } from "../../../components/Text/Text";
import { redirectPage } from "../../../hooks/redirect";
import { color, typography } from "../../../shared/style";

export const ApplyNow = ({ data }) => {
if(!data) {
  return null;
}
  return (
    <WrapperSection>
        <Wrapper>
          <Title tag="h4" capital="uppercase" color={color.primary.white} weight={typography.weight.regular1}>{data?.title?.text}</Title>
        </Wrapper>
        <Wrapper>
        <Btn type="tertiary" 
        onClick={()=>
        redirectPage({
          url: data?.button_link?.url,
          type: "accelerator-form",
          link_type: "Document"
        })
        }>{data?.button_label?.text}</Btn>
        </Wrapper>
    </WrapperSection>
  );
};
const Wrapper = styled.div``
const Btn = styled(Button)`
  margin-top: 40px;
`
const Title = styled(Text)``


const WrapperSection = styled.div`
  text-align: center;
  background-color: ${color.primary.blue};
  max-width: 1500px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  @media (max-width: 1000px) {
    padding: 60px 40px;
  }
  @media (max-width: 650px) {
    padding: 40px 20px;
  }
`;