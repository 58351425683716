import React, {useState} from "react";
import styled from "styled-components";
import banner from "../../../images/our-story-banner.png";
import { color, typography } from "../../../shared/style";
import { Text } from "../../../components/Text/Text";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../../components/RichtextHelper/CustomText";
import {isMobile} from "../../../hooks/checkMobile"
export const Fact = ({ data }) => {
  const [isSeeMore, setSeeMore] = useState(false);

  if(!data) {
    return null;
  }
  return (
    <WrapperSection>
      <WrapperGeneral>
        <Content>
          <Title
            font={typography.type.code}
            tag="h4"
            color={color.primary.blue}
            capital="uppercase"
            weight={typography.weight.regular1}
            align="left"
          >
              {/* {data.title} */}
              {/* {console.log(data.description)} */}
            <RichText
              render={data?.title?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Title>
          <ImageMobile src={data?.image?.url || banner} />
          <Description
            font={typography.type.primary}
            color={color.netraul.black80}
            size={typography.size.s3}
            weight={typography.weight.regular}
            lineHeight={26}
            align="left"
          >
              {/* {data.description} */}
            {isMobile ? (
              <RichText
              render={isSeeMore ? data?.description?.raw : data?.description?.raw.slice(0, 2)}
              htmlSerializer={htmlSerializer}
            />
            ): (
              <RichText
              render={data?.description?.raw}
              htmlSerializer={htmlSerializer}
            />
            )}
          </Description>
          {
            isMobile && !isSeeMore && <SeeMore onClick={() => setSeeMore(true)}>See more</SeeMore>
          }
        </Content>
        <Image src={data?.image?.url || banner} />
      </WrapperGeneral>
    </WrapperSection>
  );
};
const SeeMore = styled.div`
  margin-top: 14px;
  font-weight: bold;
  font-family: Oxygen;
  width: fit-content;
  color: ${color.primary.blue};
  border-bottom: 1px solid ${color.primary.blue};
  text-underline-offset: 5px;
`
const WrapperSection = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 131px;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  background: ${color.primary.white};
  @media (max-width: 1300px) {
    padding: 0 60px;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    height: auto;
    padding: 0 20px;
  }
`;
const WrapperGeneral = styled.div`
  padding: 60px 0 120px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    padding: 100px 0;
  }
  @media (max-width: 992px) {
    padding: 90px 0;
    flex-direction: column;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    padding: 80px 0 100px 0;
  }
`;
const Content = styled.div`
  flex: 0 0 51.37%;
  max-width: 51.37%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-right: 124px;
  @media (max-width: 1200px) {
    margin-top: 0;
  }
  @media (max-width: 992px) {
    gap: 0;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding-right: 0;
  }
`;
const Title = styled(Text)`
  letter-spacing: -0.015em;
  width: 62%;
  @media (max-width: 1465px) {
      width: 75%;
    }
    @media (max-width: 1156px) {
      width: 85%;
    }
    @media (max-width: 1062px) {
      width: 95%;
    }
    @media (max-width: 992px) {
      width: 35%;
    }
    @media (max-width: 705px) {
      width: 45%;
    }
    @media (max-width: 500px) {
      width: 55%;
    }
    @media (max-width: 400px) {
      width: 65%;
    }
`;
const Description = styled(Text)`
  div {
    margin-top: 32px;
    width: 81%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;
const Image = styled.img`
  display: block;
  height: fit-content;
  flex: 0 0 48.63%;
  max-width: 48.63%;
  max-height: 573px;
  @media (max-width: 1400px) {
    width: 100%;
  }
  box-sizing: border-box;
  @media (max-width: 992px) {
    display: none;
  }
`;
const ImageMobile = styled.img`
  display: none;
  width: 100%;
  margin-bottom: 32px;
  @media (max-width: 992px) {
    display: block;
    padding-top: 30px;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
