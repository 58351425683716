import React from 'react'
import styled from 'styled-components'
import { HightLight } from './HightLight'
import { Banner } from './Banner'
import { Fact } from './Fact'
import { About } from './About'
import { Investment } from './Investment'
import { ProgramDates } from './ProgramDates'
import { ApplyNow } from './ApplyNow'
// import sector from "../../../images/sector.svg"

const MainContent = ({ children, data }) => {
  return (
    <WrapperSection>
      <Banner
        title={data?.body[0]?.primary?.title?.text}
        description={data?.body[0]?.primary?.description?.text}
        button_label={data?.body[0]?.primary?.button_label?.text}
        button_link={data?.body[0]?.primary?.button_link}
      />
      {data?.body2[0] && (
      <HightLight
        data={data?.body2[0] === undefined ? {} : data?.body2[0]?.primary}
      />
      )}
      {data?.body5[0] && (  
      <Fact
        data={data?.body5[0] === undefined ? {} : data?.body5[0]?.primary}
      />
      )}
      {data?.body3[0] && (
      <Investment
        data={data?.body3[0] === undefined ? {} : data?.body3[0]?.primary}
      />
      )}
      {data?.body4[0] && (
      <About
        primary={data?.body4[0] === undefined ? {} : data?.body4[0]?.primary}
        items={data?.body4[0] === undefined ? {} : data?.body4[0]?.items}
      />
      )}
      {data?.body6[0] && (
      <ProgramDates
        items={data?.body6[0] === undefined ? {} : data?.body6[0]?.items}
        primary={data?.body6[0] === undefined ? {} : data?.body6[0]?.primary}
      />
      )}
      {data?.body7[0] && (
      <ApplyNow
        data={data?.body7[0] === undefined ? {} : data?.body7[0]?.primary}
      />
      )}
    </WrapperSection>
  );
}

export default MainContent;

const WrapperSection = styled.div`
  height: 100%;
  box-sizing: border-box;
`;
