import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Header } from "../containers/HeaderContainer";
import FooterContainer from "../containers/FooterContainer";
import useApp, { withAppContext } from "../contexts/context";
import { ThemeProvider } from "styled-components";
import { useSiteMetadata } from "../hooks/useQuerydata";
import AcceleratorContainer from '../containers/AcceleratorContainer'
import { Sidebar } from "../components/Sidebar";
import { useQueryAccelerator } from "../hooks/useQueryAccelerator";

const Accelerator = ({ data, children }) => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const { allPrismicAccelerator } = useQueryAccelerator();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={allPrismicAccelerator?.nodes[0]?.data?.body1} />
        }
      >
        <AcceleratorContainer data={allPrismicAccelerator?.nodes[0]?.data} />
        <script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=draper-startup-house"></script>
      </Layout>
    </ThemeProvider>
  );
};



export default withAppContext(Accelerator);
