import React from 'react'
import styled from 'styled-components'
import MainContent from './MainContent/MainContent'
// import { useQueryHomepage } from '../../hooks/useQueryHome'

const AcceleratorContainer = ({ data }) => {

  return (
    <WrapperSection>
      <MainContent data={data} />
    </WrapperSection>
  )
}

export default AcceleratorContainer;

const WrapperSection = styled.div`
  /* height: 100%; */
`;