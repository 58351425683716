import React from "react";
import styled from "styled-components";
import { color, typography } from "../../../shared/style";
import { Text } from "../../../components/Text/Text";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../../components/RichtextHelper/CustomText";

export const Investment = ({ data }) => {
  if(!data) {
    return null;
  }
  return (
    <WrapperSection>
      <Content>
        <WrapperText>
          <TextExtend
            font={typography.type.code}
            tag="h4"
            color={color.primary.white}
            capital="uppercase"
            weight={typography.weight.regular}
            align="center"
          >
            {/* {data.title} */}
            <RichText
                render={data?.title?.raw}
                htmlSerializer={htmlSerializer}
                />
          </TextExtend>
        </WrapperText>
        <WrapperText>
          <Description
            font={typography.type.primary}
            color={color.primary.white}
            weight={typography.weight.regular}
            align="center"
          >
            <RichText
                render={data?.description?.raw}
                htmlSerializer={htmlSerializer}
                />
          </Description>
        </WrapperText>
      </Content>
    </WrapperSection>
  );
};

const Description = styled(Text)`
`;
const TextExtend = styled(Text)``;
const WrapperText = styled.div`
    text-align: center;
    &:first-child {
      margin-bottom: 24px;
    }
`;
const Content = styled.div`
  margin: 0 auto;
  padding: 60px 20% 90px 20%;
  box-sizing: border-box;
  background: ${color.primary.blue};
  @media (max-width: 650px) {
    
    padding: 60px 20px 90px 20px;
  }
`;
const WrapperSection = styled.div`
  display: flex;
  letter-spacing: -0.015em;
  align-items: center;
  padding: 0 131px;
  height: fit-content;
  max-width: 1500px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1300px) {
    padding: 0 60px;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    height: auto;
    padding: 0 20px;
  }
`;
