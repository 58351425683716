import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Text } from "../../../components/Text/Text";
import  htmlSerializer  from "../../../components/RichtextHelper/CustomText";
import { color, typography } from "../../../shared/style";
import { RichText } from "prismic-reactjs";

export const HightLight = ({ data }) => {
  if(!data) return null;
  return (
    <Wrapper>
      <Content>
        <Title>
          <Text
            color={color.primary.blue}
            font={typography.type.code}
            weight={typography.weight.regular1}
            tag="h3"
            align="center"
            capital="uppercase"
          >
            <RichText render={data?.title?.raw} htmlSerializer={htmlSerializer} />
            {/* {data?.title?.text} */}
          </Text>
        </Title>
        <Description
          color={color.netraul.black80}
          font={typography.type.primary}
          size={typography.size.s3}
          fontWeight={typography.weight.regular}
          align="center"
          lineHeight={26}
        >
          <RichText render={data?.quote?.raw} htmlSerializer={htmlSerializer} />
          {/* {data.description} */}
        </Description>
        <By
          color={color.secondary.danube}
          font={typography.type.code}
          size={typography.size.l1}
          align="center"
          lineHeight={typography.size.l1}
          weight={typography.weight.regular1}
          capital="uppercase"
        >
          {/* <RichText render={data?.description?.raw} htmlSerializer={htmlSerializer} /> */}
          – {data.author?.text} –
        </By>
      </Content>
    </Wrapper>
  );
};

HightLight.propTypes = {
  data: PropTypes.shape({
    primary: PropTypes.shape({
      content: PropTypes.shape({
        raw: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      }),
    }),
  }),
};

const By = styled(Text)`
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    font-size: 30px !important;
  }
`;
const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: -60px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;
  box-sizing: border-box;
  @media (max-width: 1300px) {
    padding: 0 60px;
  }
  @media (max-width: 1000px) and (min-width: 650px) {
    box-sizing: border-box;
    width: 100%;
    padding: 0 40px;
  }
  @media (max-width: 649px) {
    margin-top: -70px !important;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
`;

const Content = styled.div`
  background-color: ${color.primary.white};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 100px 8%;
  @media (max-width: 1200px) {
    padding: 60px 8%;
  }
  @media (max-width: 650px) {
    gap: 12px;
  padding: 50px 8% 40px 8%;
  }
`;

const Title = styled.div`
  width: 60%;
  span {
    letter-spacing: -0.015em;
  }
  @media (max-width: 650px) {
    width: auto;
  }
`;
const Description = styled(Text)`
  padding: 0 22%;
  margin: 22px 0 24px 0;
  @media (max-width: 1000px) {
    padding: 0;
  }
`;
