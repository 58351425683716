import React from "react";
import styled from "styled-components";
import { Link } from "../../../components/Link/Link";
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import { redirectPage } from "../../../hooks/redirect";

export const ProgramDates = ({ items, primary }) => {
  if (!items || !primary) {
    return null;
  }
  return (
    <WrapperSection>
      <WrapperInfo>
        <Wrapper>
          <Title
            tag="h3"
            capital="uppercase"
            color={color.primary.blue}
            weight={typography.weight.regular1}
          >
            {primary?.title?.text}
          </Title>
        </Wrapper>
        <WrapperDescription>
          <Description
            color={color.netraul.black100}
            font={typography.type.primary}
            weight={typography.weight.regular}
          >
            {primary?.description?.text}
          </Description>
        </WrapperDescription>
      </WrapperInfo>
      <WrapperItem>
        {items?.map((item) => (
          <Item>
            <WrapperImage>
              <Image src={item?.thumbnail?.url} />
            </WrapperImage>
            <Info>
              <Name
                capital="uppercase"
                tag="h4"
                color={color.primary.blue}
                weight={typography.weight.regular1}
              >
                {item?.location?.text}
              </Name>
              <Date
                font={typography.type.primary}
                color={color.netraul.black100}
                weight={typography.weight.bold}
              >
                {item.start_date} – {item.end_date}
              </Date>
              <Detail
                font={typography.type.primary}
                color={color.netraul.black100}
                weight={typography.weight.regular}
                lineHeight={22}
                size={14}
              >
                {item?.description?.text}
              </Detail>
              <Apply
                font={typography.type.primary}
                color={color.primary.blue}
                weight={typography.weight.bold}
                onClick={()=>
                  redirectPage({
                    url:"",
                    type: "accelerator-form",
                    link_type: "Document"
                  })
                  }
              >
                {item?.link_label?.text}
              </Apply>
            </Info>
          </Item>
        ))}
      </WrapperItem>
    </WrapperSection>
  );
};
const WrapperInfo = styled.div`
  text-align: center;
  width: 37%;
  margin: 0 auto;
  @media (max-width: 1450px) {
    width: 50%;
  }
  @media (max-width: 1150px) {
    width: 70%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const WrapperDescription = styled.div`
  padding-bottom: 80px;
  padding-top: 32px;
  @media (max-width: 650px) {
    padding-bottom: 60px;
    padding-top: 24px;
  }
`;
const Wrapper = styled.div``;
const WrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
const Item = styled.div`
  width: calc(50%);
  display: flex;
  flex-direction: row;
  background-color: ${color.primary.white};
  @media (max-width: 1100px) {
    width: 100%;
    flex-direction: column;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 34px 24px;
`;
const Name = styled(Text)`
  padding-bottom: 20px;
`;
const Date = styled(Text)``;
const Apply = styled(Link)`
  text-decoration: underline;
  text-underline-offset: 5px;
`;
const Detail = styled(Text)`
  padding-top: 16px;
  padding-bottom: 20px;
`;
const Title = styled(Text)``;
const Description = styled(Text)``;
const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 320px;
  object-fit: cover;
`;
const WrapperImage = styled.div`
  height: 100%;
  width: 100%;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

const WrapperSection = styled.div`
  background-color: ${color.primary.blue10};
  max-width: 1500px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 150px 130px;
  @media (max-width: 1300px) {
    padding: 80px 60px;
  }
  @media (max-width: 1000px) {
    padding: 80px 40px;
  }
  @media (max-width: 650px) {
    padding: 60px 20px;
  }
`;
