import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button/Button";
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import PropTypes from "prop-types";
import { redirectPage } from "../../../hooks/redirect";

export const Banner = ({ title, description, button_label, button_link }) => {
  // const { isMobile } = useApp()
  return (
    <WrapperSection>
      <Content>
        <TextExtend
          font={typography.type.code}
          tag="h2"
          color={color.primary.white}
          capital="uppercase"
          weight={typography.weight.regular1}
          align="center"
        >{title}
        </TextExtend>
        <Description 
          font={typography.type.primary}
          color={color.primary.white}
          weight={typography.weight.bold}
          align="center"
        >
          {description}
        </Description>
          <GroupButton>
              <>
                <ButtonExtended
                  type="tertiary"
                  onClick={() => 
                    redirectPage({
                      url: button_link?.url,
                      type: "accelerator-form",
                      link_type: "Document"
                    })
                  } >
                  {button_label}
                </ButtonExtended>
              </>
            
          </GroupButton>
      </Content>
    </WrapperSection >
  );
};

Banner.propTypes = {
  title: PropTypes.string,
};

Banner.defaultProps = {
  // title: "Join a global community of entrepreneurs and creators"
  data: {
    primary: {
      banner: {
        url:
          "https://images.prismic.io/draper-startup-house/a7d8ab1e-6550-46be-8c21-34430b8e1272_Copy+of+Academy-Group+1.png?auto=compress%2Cformat",
        alt: null,
        thumbnails: null,
      },
      description: {
        html: "<p>JOIN A GLOBAL COMMUNITY OF ENTREPRENEURS AND CREATORS</p>",
        text: "JOIN A GLOBAL COMMUNITY OF ENTREPRENEURS AND CREATORS",
        raw: [
          {
            type: "paragraph",
            text: "JOIN A GLOBAL COMMUNITY OF ENTREPRENEURS AND CREATORS",
            spans: [],
          },
        ],
      },
    },
  },
};
const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const ButtonExtended = styled(Button)`
  line-height: 26px;
  white-space: nowrap;
  margin: 0px auto;
  width: fit-content;
  text-transform: capitalize;
`;
const Description = styled(Text)`
  padding-bottom: 40px;
  @media (max-width: 650px) {
    padding-bottom: 30px;
  }
`
const TextExtend = styled(Text)`
  padding-bottom: 30px;
  @media (max-width: 1000px) and (min-width: 500px){
    font-size: ${typography.size.code}px !important;
    line-height: 90px !important;
  }
  @media (max-width: 500px) {
    padding-bottom: 16px;
    font-size: ${typography.size.l4}px !important;
    line-height: 62px !important;
  }
`;
const WrapperSection = styled.div`
  padding-top: 90px;
  box-sizing: border-box;
  display: flex;
  background: linear-gradient(180deg, #2A436C 0%, rgba(0, 0, 0, 0) 100%, #666DA7 100%), linear-gradient(0deg, #4D5391, #4D5391), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(dsh-accelerator-1.jpg), url(image.png);
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 1300px) {
    padding: 90px 60px 0;
  }
  @media (max-width: 1000px) {
    padding: 90px 40px 0;
  }
  @media (max-width: 650px) {
    align-items: unset;
    height: auto;
    padding: 0 20px;
  }
`;
const Content = styled.div`
  display: flex;
  position: relative;
  width: 55%;
  flex-direction: column;
  padding-top: 113px;
  padding-bottom: 179px;
  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
    padding-top: 150px;
    padding-bottom: 169px;
  }
`;
